import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowRightFromBracket, faGear, faSliders } from "@fortawesome/free-solid-svg-icons";
import { faUser, faQuestionCircle, faCommentDots  } from '@fortawesome/free-regular-svg-icons';

const Settings = () => {
  

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const history = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history("/login");
    } else {
      if (!user || !user.name) {
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, history, userInfo, user]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (password != confirmPassword) {
      setMessage("Les mots de passe ne sont pas identiques!");
    } else {
      console.log("updating...");
    }
  };

  return (
    <div className="col-lg-8 col-12">
    <div className="card border-0 rounded shadow">
      <div className="card-body">
        <h5 className="text-md-left text-center">
          Paramètres du compte 
        </h5>
        <div className="mt-3 text-md-left text-center d-sm-flex">

        <FontAwesomeIcon icon={faUser} color="#EEEEEE" className="avatar float-md-left avatar-small rounded-circle shadow mr-md-4"/>
          <div className="mt-md-4 mt-3 mt-sm-0">
            <a
              href="javascript:void(0)"
              className="btn btn-primary mt-2"
            >
              Changer Photo
            </a>
            <a
              href="javascript:void(0)"
              className="btn btn-outline-primary mt-2 ml-2"
            >
              Supprimer
            </a>
          </div>
        </div>

        <form>      
          <div className="row mt-4">
              <div className="col-lg-6">
              <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group position-relative">
                <label>Votre Nom & Prénom</label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-user fea icon-sm icons"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <input
                  name="name"
                  id="first"
                  type="name"
                  className="form-control pl-5"
                  placeholder="Nom & Prénom :"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required=""
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group position-relative">
                <label>Votre Email</label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-mail fea icon-sm icons"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control pl-5"
                  placeholder="Email :"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required=""
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <input
                type="submit"
                id="submit"
                name="send"
                className="btn btn-primary"
                value="Enregistrer"
              />
            </div>
          </div>
        </form>
        </div>
        <div className="col-lg-6">
                <div className="row">
              <div className="col-lg-12">
                <div className="form-group position-relative">
                  <label>Ancien mot de passe :</label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-key fea icon-sm icons"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </svg>
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Ancien mot de passe"
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group position-relative">
                  <label>Nouveau mot de passe :</label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-key fea icon-sm icons"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </svg>
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Nouveau mot de passe"
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group position-relative">
                  <label>Re-taper le nouveau mot de passe :</label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-key fea icon-sm icons"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </svg>
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Re-taper le nouveau mot de passe"
                  />
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-sm-12">
              <input
                type="submit"
                id="submit"
                name="send"
                className="btn btn-primary"
                value="Enregistrer mot de passe"
              />
            </div>
          </div>
          </div>
          </div>
        </form>    
      </div>
    </div>
  </div>
  )
}

export default Settings