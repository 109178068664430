import React from "react";

const Pagination = ({ questionsPerPage, totalQuestions, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalQuestions / questionsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <ul className="pagination mb-0">
        <li className="page-item">
          <a className="page-link" onClick={() => paginate(currentPage-1)}  aria-label="Précédent">
          Précédent
          </a>
        </li>
        {pageNumbers.map((number) => (
          <li key={number} className={`${number === currentPage ? 'active' : null } page-item`}>
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}       
        <li className="page-item">
          <a className="page-link" onClick={() => paginate(currentPage+1)} aria-label="Suivant">
          Suivant
          </a>
        </li>
      </ul>
    </>
  );
};

export default Pagination;
