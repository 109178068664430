import React from 'react'
import { useLocation } from 'react-router-dom'
function Screen404() {
    let location= useLocation();
    return (
        <div>
            Error {location}
        </div>
    )
}

export default Screen404
