import React, { useEffect, useState } from "react";
import axios from "axios";
import Question from "../components/Question";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import TitleIcon from "@material-ui/icons/TitleOutlined";
import MessageIcon from "@material-ui/icons/MessageOutlined";
import Pagination from "../components/Pagination";

function Main() {
  //get id of the question
  let params = useParams();

  //get user info
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  //get questions
  const [questions, setQuestions] = useState([]);

  const getQuestions = () => {
    if (!params.id) {
      axios.get("https://forum.gsoft.tn/api/questions/").then((response) => {
        const myquestions = response.data;
        setQuestions(myquestions);
      });
    } else {
      axios
        .get(`https://forum.gsoft.tn/api/subjects/${params.id}/`)
        .then((response) => {
          const myquestions = response.data;
          myquestions.map((item) => {
            setQuestions(item.question);
          });
        });
    }
  };
  useEffect(() => getQuestions(), [params.id]);

  const [searchTerm, setSearchTerm] = useState("");

  /* Handle poser une question button and UI */
  const [showAskQ, setAskQ] = useState(false);
  const onClick = () => setAskQ(true);
  const onClose = () => setAskQ(false);
  const AskUI = () => (
    <div className="container pb-60">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12  mt-4 pt-2">
          <div className="card rounded bg-light border-0 shadow-1">
            <div className="card-body py-5">
              <h4 className="card-title border-bottom">Poser une question</h4>
              <div className="custom-form mt-4">
                <div id="message"></div>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group position-relative">
                        <label>
                          Titre <span className="text-danger">*</span>
                        </label>
                        <TitleIcon fontSize="small" className="icons" />
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control pl-5"
                          placeholder="Votre question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                      </div>
                    </div>
                    {/*                                         <div className="col-md-12">
                                      <div className="form-group position-relative">
                                          <label>Sujets</label>
                                          <i data-feather="book" className="fea icon-sm icons"></i>
                                          <input name="subject" id="subject" type="text" className="form-control pl-5" placeholder="Subject"/>
                                      </div>                                                                               
                                  </div> */}
                    <div className="col-md-12">
                      <div className="form-group position-relative">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <MessageIcon fontSize="small" className="icons" />
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control pl-5"
                          placeholder="Détails"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-sm-2 text-center">
                      <button
                        onClick={onClose}
                        className="btn btn-outline-secondary"
                      >
                        Fermer
                      </button>
                    </div>
                    <div className="col-sm-3 text-center">
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary btn-block"
                        value="Publier"
                        onClick={createQuestion}
                      />
                      <div id="simple-msg"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //Add new question
  const [question, setQuestion] = useState("");
  const [desc, setDesc] = useState("");
  function createQuestion(event) {
    axios.post(`https://forum.gsoft.tn/api/questions/`, {
      desc: desc,
      question: question,
      user: userInfo.id,
    });
    console.log(formQuestion.question);
    /*       .then((response) => {
        getAnwsers();
      }); */

    event.preventDefault();
  }

  // Get current questions - Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(5);

  const indexofLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexofLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(indexOfFirstQuestion, indexofLastQuestion);
   
  // Change page on Click
  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
      <Header setSearchTerm={setSearchTerm} />
      <div>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {showAskQ ? null : (
                <div className="subcribe-form pb-60 btn-question">
                  <button
                    onClick={onClick}
                    className="btn btn-primary btn-color-blue"
                  >
                    Poser une Question
                  </button>
                </div>
              )}
              {showAskQ ? <AskUI /> : null}

              {currentQuestions
                .filter((q) => {
                  if (searchTerm == "") {
                    return <Question q={q} key={q.id} />;
                  } else if (
                    q.question
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    q.desc.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return <Question q={q} key={q.id} />;
                  }
                })
                .map((q) => {
                  return <Question q={q} key={q.id} />;
                })}
                <Pagination questionsPerPage={questionsPerPage} totalQuestions={questions.length} paginate={paginate} currentPage={currentPage} />
            </div>
            <SideBar questions={questions} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
