import React, { useState, useEffect } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowRightFromBracket, faGear, faSliders } from "@fortawesome/free-solid-svg-icons";
import { faUser, faQuestionCircle, faCommentDots  } from '@fortawesome/free-regular-svg-icons';
import { getUserDetails } from "../actions/userActions";


function ProfileScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const history = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history("/login");
    } else {
      if (!user || !user.name) {
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, history, userInfo, user]);

  return (
    <>
      <section class="d-table w-100 mt-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="card public-profile border-0 rounded shadow"
                style={{ zIndex: 1 }}
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-lg-2 col-md-3 text-md-left text-center">
                    <FontAwesomeIcon icon={faUser}className="avatar avatar-large rounded-circle shadow d-block mx-auto" color="#eeeeee"/>                  
                    </div>
                    <div class="col-lg-10 col-md-9">
                      <div class="row align-items-end">
                        <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                            <h3 class="title mb-0">{name}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section pt-none">
        <div className="container mt-lg-3">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 d-lg-block d-none">
              <div className="sidebar sticky-bar p-4 rounded shadow">
                <div class="widget mt-4">
                  <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                    <li class="navbar-item account-menu px-0 active">
                      <a
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                        href="#"
                      >
                        <span class="h4 mb-0">
                        <FontAwesomeIcon icon={faUser} />
                        </span>
                        <h6 class="mb-0 ms-2">Profil</h6>
                      </a>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                        href="#"
                      >
                        <span class="h4 mb-0">
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                        <h6 class="mb-0 ms-2">0 Questions</h6>
                      </a>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                        href="#"
                      >
                        <span class="h4 mb-0">
                          <FontAwesomeIcon icon={faCommentDots} />
                        </span>
                        <h6 class="mb-0 ms-2">0 Réponses</h6>
                      </a>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <Link to={"parametres"} className="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0">
                          <FontAwesomeIcon icon={faGear} />
                        </span>
                        <h6 class="mb-0 ms-2">Paramètres</h6>
                      </Link>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                        href="#"
                      >
                        <span class="h4 mb-0">
                          <FontAwesomeIcon icon={faArrowRightFromBracket}/>
                        </span>
                        <h6 class="mb-0 ms-2">Se déconnecter</h6>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>    
            <Outlet />   
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfileScreen;
