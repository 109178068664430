import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ModeCommentOutlined from "@material-ui/icons/ModeCommentOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Person from "@material-ui/icons/Person";
import CalendarToday from "@material-ui/icons/CalendarTodayOutlined";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaCopy } from "react-icons/fa";
import Answer from "../components/Answer";
import { useDispatch, useSelector } from "react-redux";
import Login from "../components/Login";
import Header from "../components/Header";

const QuestionScreen = () => {
  //get id of the question
  const [params, setParams] = useState(useParams());

  //get question object from the Rest API
  const [question, setQuestion] = useState([]);
  const [like_color, setLike_color] = useState("");
  const [like, setLike] = useState();
  const [userQ, setUserQ] = useState("Anonymous")
  
  //get user info
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  useEffect(() => {
    fetch(`https://forum.gsoft.tn/api/questions/${params.id}/`)
      .then((response) => {
        return response.json();
      })
      .then((question) => {
        const desc_d = question.desc.replace(/<[^>]+>/g, "");
        question.desc = desc_d;
        setQuestion(question);
        setLike(question.likes.length);

        const test = question.likes.includes(userInfo.id);
        if (test == false) {
          setLike_color("#8492a6");
        } else {
          setLike_color("#037ffc");
        }

        // Get user of the question    
        axios({
          method: "GET",
          url: `https://forum.gsoft.tn/api/users/${question.user}/`
        }) 
        .then((response) => {
          const data = response.data;
          setUserQ(data);
          console.log(data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });      
      });
  }, []);

  useEffect(() => {
    axios.post(`https://forum.gsoft.tn/api/questions/${params.id}/view/`);
  }, []);

  //format date
  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  //save and display new answer
  const [answers, setNewAnswers] = useState(null);
  const [formAnswer, setFormAnswer] = useState({
    answer: "",
  });
  const [countAnswer, setCountAnswer] = useState();

  useEffect(() => {
    getAnwsers();
  }, []);

  function getAnwsers() {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/answer_api/answers/?question=${params.id}&answer__isnull=true`,
    })
      .then((response) => {
        const data = response.data;
        setCountAnswer(data.length);
        setNewAnswers(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  function createAnswer(event) {
    axios
      .post(`https://forum.gsoft.tn/answer_api/answers/`, {
        desc: formAnswer.answer,
        question: params.id,
        user: userInfo.id,
      })
      .then((response) => {
        getAnwsers();
      });

    setFormAnswer({
      answer: "",
    });
    event.preventDefault();
  }

  function handleChange(event) {
    const { value, name } = event.target;
    setFormAnswer((answers) => ({
      ...answers,
      [name]: value,
    }));
  }

  //Like button Toggle
  const history = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  function handleLike(event) {
    event.preventDefault();
    if (!userInfo) {
      history("/login");
    } else {
      axios
        .post(
          `https://forum.gsoft.tn/api/questions/${params.id}/like/`,
          {},
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          axios({
            method: "GET",
            url: `https://forum.gsoft.tn/api/questions/${params.id}/`,
          }).then((response) => {
            const dataa = response.data;
            setLike(dataa.likes.length);
            const teset = dataa.likes.includes(userInfo.id);
            if (teset == true) {
              setLike_color("#037ffc");
            } else {
              setLike_color("#8492a6");
            }
          })
        )
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    }
  }

  //get subjects by question
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/api/subjects/questions/${params.id}/`,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        setSubjects(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  return (
    <>
      <Header />
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-md-2 d-none d-md-block">
                  <ul className="list-unstyled text-center sticky-bar social-icon mb-0">
                    <li className="mb-3 h6">Partager</li>
                    <li>
                      <a href="" className="rounded">
                        <FaCopy />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="rounded">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="rounded">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="rounded">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-10 page-next-level content">
                  <h4 className="title title-dark text-purple">
                    {question.question}
                  </h4>

                  <ul className="questionScreen list-unstyled d-flex justify-content-between mt-4">
                    <li className="list-inline-item user me-2">
                      <a href="#" className="text-muted">
                        <Person className="text-dark" />
                        {"  "}{" "}
                        {userQ.name}
                      </a>
                    </li>

                    <li className="list-inline-item date text-muted">
                      <CalendarToday className="text-dark" />{" "}
                      {formatDate(question.created_at)}
                    </li>
                  </ul>

                  <p className="bg-light p-4 rounded text-muted">
                    {question.desc}
                  </p>
                  <div className="post-meta mt-3">
                    <ul className="list-unstyled mb-0">
                      <li className="list-inline-item mr-2">
                        <a
                          href="#"
                          className="text-muted view"
                          onClick={handleLike}
                          style={{ color: like_color }}
                        >
                          <FavoriteBorderIcon fontSize="small" />
                          {like}
                        </a>
                      </li>
                      <li className="list-inline-item mr-2">
                        <a href="#commentArea" className="text-muted like">
                          <ModeCommentOutlined fontSize="small" /> {countAnswer}
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#" className="text-muted comments">
                          <VisibilityOutlinedIcon fontSize="small" />{" "}
                          {question.views}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="post-meta mt-3">
                    <ul className="list-unstyled mb-0">
                      {subjects.map((subject) => {
                        return (
                          <li
                            key={subject.id}
                            className="list-inline-item mr-2"
                          >
                            <Link
                              to={`/${subject.id}`}
                              className="rounded tags"
                            >
                              {subject.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <h5 className="mt-4">{countAnswer} Réponses :</h5>
                  {answers &&
                    answers.map((a) => {
                      return <Answer a={a} key={a.id} />;
                    })}
                  <h5 className="mt-4" id="commentArea">
                    Laissez votre Réponse :
                  </h5>

                  {userInfo ? (
                    <form className="mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Votre Réponse</label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="message-circle"
                                className="fea icon-sm icons"
                              ></i>
                              <textarea
                                id="message"
                                placeholder="Écrire ici..."
                                rows="5"
                                name="answer"
                                onChange={handleChange}
                                value={formAnswer.answer}
                                className="form-control ps-5"
                                required=""
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="send d-grid">
                            <button
                              className="btn btn-primary"
                              onClick={createAnswer}
                            >
                              Publier votre Réponse
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Login />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuestionScreen;
