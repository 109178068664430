import React from "react";
import ContactSupportOutlined from "@material-ui/icons/ContactSupportOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import WriterQuestion from "./WriterQuestion";

function SideBar({ questions }) {
  return (
    <div className="col-lg-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
      <div className="card border-0 sidebar sticky-bar rounded shadow bg-light">
        <div className="card-body">
          <div className="widget mb-4 pb-2">
            <h5 className="widget-title">Questions populaires</h5>
            <ul className="list-unstyled mb-0 mt-4">
              {questions
                .slice(0, 4)
                .sort((a, b) => (a.views < b.views ? 1 : -1))
                .map((question) => {
                  return (
                    <li className="text-muted small h6">
                      <ContactSupportOutlined
                        fontSize="small"
                        color="#000000"
                      />
                      <Link
                        to={`/question/${question.id}`}
                        className="text-dark"
                      >
                        {question.question}
                      </Link>
                      {" "} - {" "}
                      <WriterQuestion question={question} />
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="widget">
            <h5 className="widget-title">Suivez-nous</h5>
            <ul className="list-unstyled social-icon social mb-0">
              <li className="list-inline-item">
                <a
                  href="https://www.facebook.com/GSoftOfficielle/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookF} color="#3c4858" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.linkedin.com/company/gsoftofficielle/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} color="#3c4858" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
