import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import axios from 'axios';

const WriterQuestion = ({ question }) => {

  // Get the writer of the question
  const [user, setUser] = useState("Anonymous");
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/api/users/${question.user}/`,
    })
      .then((response) => {
        const data = response.data;
        setUser(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  return (
    <Link to={`/profile/`} className="text-muted">
      {user.name}
    </Link>
  );
};

export default WriterQuestion;
