import React from "react";

function Footer() {
  return (
    <div>
      <footer className="footer bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <h4 className="text-dark footer-head">Communauté</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a target="_blank" href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Forum
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Actualités
                  </a>
                </li>
                <li>
                  <i data-feather="phone" className="fea icon-sm"></i>
                  <a href="tel:+216" className="text-muted">
                    +216
                  </a>
                </li>
                <li>
                  <i data-feather="mail" className="fea icon-sm"></i>
                  <a href="mailto:" className="text-muted"></a>
                </li>
              </ul>
              <ul className="list-unstyled social-icon social mb-0 mt-4">
                <li className="list-inline-item">
                  <a href="#" className="rounded" target="_blank">
                    <i
                      data-feather="facebook"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="rounded" target="_blank">
                    <i
                      data-feather="linkedin"
                      className="fea icon-sm fea-social"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-dark footer-head">Entreprise</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>À propos de
                    nous
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Devenir
                    partenaire
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Assistance
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Contactez-nous
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-dark footer-head">Liens Utiles</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Nos packs
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Tous nos
                    produits
                  </a>
                </li>
                <li>
                  <a href="#" className="text-muted">
                    <i className="mdi mdi-chevron-right mr-1"></i>Nos références
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-dark footer-head">Newsletter</h4>
              <p className="mt-4 text-muted">
                Inscrivez-vous et recevez nos derniers conseils par e-mail.
              </p>
              <form method="POST" id="NewsletterForm" action="">
                {/* {% csrf_token %} */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="foot-subscribe foot-white form-group position-relative">
                      <label className="text-muted">
                        Écrivez votre email
                        <span className="text-danger">*</span>
                      </label>
                      <i data-feather="mail" className="fea icon-sm icons"></i>
                      <input
                        type="email"
                        name="emailsubscribe"
                        id="emailsubscribe"
                        className="form-control bg-light border pl-5 rounded"
                        placeholder="Votre email : "
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="submit"
                      name="send"
                      className="btn btn-primary btn-block"
                      value="S'abonner"
                    />
                  </div>
                </div>
                <div
                  className="alert alert-success box-ajax alert-dismissible fade show mt-20"
                  role="alert"
                  id="success-box-news"
                >
                  <strong>Succès!</strong> Vous êtes maintenant inscrit à notre
                  Newsletter !
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true"> × </span>
                  </button>
                </div>
                <div
                  className="alert alert-danger box-ajax alert-dismissible fade show mt-20"
                  role="alert"
                  id="error-box-news"
                >
                  <strong>Erreur!</strong> réessayer ultérieurement !
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true"> × </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer bg-light">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-left">
                <p className="mb-0">
                  &copy; 2020 -
                  <script>document.write(new Date().getFullYear());</script>
                  GSoft. All rights reserved. Powered by{" "}
                  <span className="text-primary font-weight-bold">
                    <a href="https://www.diese.tn/" target="_blank">
                      Diese
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul className="list-unstyled text-sm-right mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="images/payments/american-ex.png"
                      className="avatar avatar-ex-sm"
                      title="American Express"
                      alt=""
                    />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="images/payments/discover.png"
                      className="avatar avatar-ex-sm"
                      title="Discover"
                      alt=""
                    />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="images/payments/master-card.png"
                      className="avatar avatar-ex-sm"
                      title="Master Card"
                      alt=""
                    />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="images/payments/paypal.png"
                      className="avatar avatar-ex-sm"
                      title="Paypal"
                      alt=""
                    />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="#">
                    <img
                      src="images/payments/visa.png"
                      className="avatar avatar-ex-sm"
                      title="Visa"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
