import React, { useState } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { NavDropdown, Dropdown } from "react-bootstrap";
import { logout } from "../actions/userActions";


function Header({setSearchTerm}) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  

  return (
    <header id="topnav" className="defaultscroll sticky bg-light">
      <div className="container">
        <div>
          <Link to="/" className="logo" href="https://gsoft.tn/">
            <img src={logo} height="24" alt="Logo GSoft" />
          </Link>
        </div>

        {userInfo ? (
          <div className="buy-button">
            <NavDropdown title={userInfo.name} id="username" className="pt-none-username">
              <LinkContainer to="/profil">
                <NavDropdown.Item className="line_height-35"> Profil </NavDropdown.Item>
              </LinkContainer>
              <div className="dropdown-divider"></div>
              <NavDropdown.Item className="line_height-35" onClick={logoutHandler}>
                {" "}
                Déconnexion
              </NavDropdown.Item>
            </NavDropdown>
          </div> 

        ) : (
          <>
            <div className="buy-button">
              <Link to="/register" className="btn btn-outline-primary">
                S'inscrire
              </Link>
            </div>
            <div className="buy-button">
              <Link to="/login/" className="btn btn-primary">
                Login
              </Link>
            </div>
          </>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <a className="navbar-toggle">
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
        <div id="navigation">
         
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 text-center pt-05rem">
                  <div className="pages-heading">
                    
                    <div className="subcribe-form pt-2">
                      <form>
                        <div className="mb-0">
                          <input
                            type="text"
                            id="help"
                            name="name"
                            className="border bg-white rounded-pill"
                            required=""
                            placeholder="Recherchez..."
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        </div>
      </div>
    </header>
  );
}

export default Header;
