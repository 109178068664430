import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserAlt } from "react-icons/fa";
import NAnswer from "./NAnswer";

function Answer({ a }) {
  // take off tags from the description field
  let desc = a.desc;
  let desc_d = desc.replace(/<[^>]+>/g, "");

  //format date
  function formatDate(string) {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(string).toLocaleDateString([], options);
  }

  //get user of the answer
  const [user, setUser] = useState("");
  useEffect(() => {
    fetch(`https://forum.gsoft.tn/api/users/${a.user}/`)
      .then((response) => {
        return response.json();
      })
      .then((user) => {
        setUser(user);
      });
  }, []);
 
  const [nanswers, setNNewAnswers] = useState([]);

  const getNAnswers = () => {
    axios
      .get(
        `https://forum.gsoft.tn/answer_api/answers/?question=${a.question}&answer__isnull=false`
      )
      .then((response) => {
        console.log(response.data);
        setNNewAnswers(response.data);
      });
  };
  useEffect(() => getNAnswers(), []);

  return (
    <ul key={a.id} className="media-list list-unstyled mb-0">
      <li className="mt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div className="pe-3">
              <FaUserAlt className="user-icon pt-1 avatar avatar-md-35 rounded-circle" />
            </div>
            <div className="flex-1 commentor-detail">
              <h6 className="mb-0">
                <a href="#" className="media-heading text-dark">
                  {user.name}
                </a>
              </h6>
              <small className="text-muted">{formatDate(a.created_at)} </small>
            </div>
          </div>
          <a href="#" className="text-muted">
            <i className="mdi mdi-Répondre"></i> Répondre
          </a>
        </div>
        <div className="mt-3">
          <p className="text-muted fst-italic p-3 bg-light rounded">{desc_d}</p>
        </div>
      </li>
      {nanswers.map((na) => {
        if (na.answer === a.id) {
          return <NAnswer na={na} key={na.id} />;
        }
      })}
    </ul>
  );
}

export default Answer;
