import React, { useState, useEffect } from "react";
import axios from "axios";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ModeCommentOutlined from "@material-ui/icons/ModeCommentOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { Link } from "react-router-dom";

const Question = ({ q }) => {
  // take off tags from the description field
  let desc = q.desc;
  let desc_d = desc.replace(/<[^>]+>/g, "");

  //get subjects by question
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/api/subjects/questions/${q.id}/`,
    })
      .then((response) => {
        const data = response.data;
        setSubjects(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  function handleSubject(event) {}

  // Get number of answers
  const [answers, setAnswers] = useState(0);
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/answer_api/answers/?question=${q.id}&answer__isnull=true`,
    })
    .then((response) => {
      const data = response.data;
      setAnswers(data.length);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
    
  }, [])
  
  // Get user
  const [user, setUser] = useState("Anonymous")
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://forum.gsoft.tn/api/users/${q.user}/`
    }) 
    .then((response) => {
      const data = response.data;
      setUser(data);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  }, [])

  // Function to format Date
  function formatDate(date) {                                         
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}

  return (
    <div
      key={q.id}
      className="card rounded border-0 shadow-1 overflow-hidden mb-1rem"
    >
      <div className="row align-items-center no-gutters">
        <div className="col-md-12">
          <div className="card-body content">
            <h5>
              <Link
                to={`/question/${q.id}`}
                className="card-title title text-dark"
              >
                {q.question}
              </Link>
            </h5>
            <p className="text-muted mb-0 question-desc">{desc_d}</p>
            <div className="post-meta d-flex justify-content-between mt-3">
              <ul className="list-unstyled mb-0">
                <li className="list-inline-item mr-2 mb-0">
                  <a href="#" className="text-muted view">
                    <FavoriteBorderIcon fontSize="small" /> {q?.likes?.length}
                  </a>
                </li>
                <li className="list-inline-item mr-2 mb-0">
                  <Link to={`/question/${q.id}`} className="text-muted like">
                    <ModeCommentOutlined fontSize="small" /> {answers}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="text-muted comments">
                    <VisibilityOutlinedIcon fontSize="small" /> {q.views}
                  </a>
                </li>
              </ul>
              <small className="text-muted">
                publié par <span className="text-primary fw-bold"> {user.name}</span> le {formatDate(q.created_at)}
              </small>
            </div>
            <h6 className="pt-1rem">
              <LocalOfferIcon className="text-tag size-1rem" />
              {subjects.map((subject) => {
                return (
                  <Link
                    to={`/${subject.id}`}
                    key={subject.id}
                    className="text-tag"
                    onClick={handleSubject}
                  >
                    {" "}
                    {subject.name}{" "}
                  </Link>
                );
              })}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
