import React from "react";
import Footer from "./components/Footer";
import Main from "./screens/HomeScreen";
import QuestionScreen from './screens/QuestionScreen'
import Screen404 from './screens/Screen404'
import LoginScreen from './screens/LoginScreen'
import Settings from "./components/Settings";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import Header from "./components/Header";


function App() {
  return (
    <HashRouter hashType='noslash'>
      <Header />
      <Routes>
        <Route path='/' element={<Main />} exact />
        <Route path='/:id' element={<Main />} exact />
        <Route path='/login' element={<LoginScreen />}  />
        <Route path='/register' element={<RegisterScreen />}  />
        <Route path='/profil' element={<ProfileScreen />} >
          <Route path='/profil/parametres' element={<Settings />} />
        </Route>  
        <Route path='/question/:id' element={<QuestionScreen />} />
        <Route path='*' element={<Screen404 />} />
      </Routes>
      <Footer />
  </HashRouter>
  );
}

export default App;
