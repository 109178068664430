import React from "react";
import Login from "../components/Login";

function LoginScreen() {
  return (
    <section className="bg-home bg-circle-gradiant d-flex align-items-center">
      <div className="bg-overlay bg-overlay-white"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <Login />
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginScreen;
